import React, { useState } from "react";
import styled from "styled-components";
import { Page } from "../components/wrapper";
import breakpoint from "styled-components-breakpoint";
import { FiChevronsDown, FiSave } from "react-icons/fi";
import { Element, scroller } from "react-scroll";
import moment from "moment";

const Image = styled.div`
  background-image: url(${require("../static/images/familie-sohm.jpg")});
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const BackgroundOverlay = styled.div`
  display: flex;
  flex: 1;
  background-color: ${({ theme }) => theme.colors.opacegray};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ScrollWrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`;

const ScrollItem = styled(Element)`
  height: ${({ full }) => (full === "true" ? `100%` : null)};
  width: 100%;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 3em;
  text-align: center;
  ${breakpoint("md")`
    font-size: 6em;
  `}
  font-weight: bold;
  font-family: Damion;
  padding: 0 1em;
`;

const SubTitle = styled.div`
  font-size: 0.87em;
  text-align: center;
  margin-top: 1em;
  ${breakpoint("md")`
    margin-top: 0px;
    font-size: 1.25em;
  `}
  font-weight: normal;
  letter-spacing: 0.2em;
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  padding: 0 1em;
`;

const ScrollHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ScrollIconWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ScrollIcon = styled.div`
  width: 2em;
  height: 2em;
  margin: 1em;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const ScrollItemContent = styled.div`
  margin: 0 auto;
  max-width: 700px;
  padding: 1.5em 1em;
`;

const ScrollItemContentTitle = styled.div`
  font-size: 1.75em;
  ${breakpoint("md")`
    font-size: 2em;
  `};
  font-family: Damion;
  margin: 0.5em 0;
`;

const ScrollItemContentText = styled.div`
  margin: 0.5em 0;
`;

const ScrollItemContentInput = styled.input`
  padding: 0.5em;
  border: none;
  outline: none;
  background: #80808045;
  margin: 1.5em 0 0 0;
  width: 250px;
  font-size: 1.25em;
`;

const DownloadItemWrapper = styled.a`
  display: flex;
  flex-direction: row;
  color: unset;
  text-decoration: unset;
  padding: 1em;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.lightgray};
  margin-bottom: 1em;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.2) 1px 0px 20px 1px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
`;

const DownloadItemList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5em;
`;

const DownloadItemIcon = styled.div`
  margin-right: 1em;
  width: 1em;
  height: 1em;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

const Background = ({ children }) => (
  <Image>
    <BackgroundOverlay>{children}</BackgroundOverlay>
  </Image>
);

const DownloadItem = ({ url, icon, text }) => (
  <DownloadItemWrapper href={url}>
    <DownloadItemIcon>{icon}</DownloadItemIcon>
    <div>{text}</div>
  </DownloadItemWrapper>
);

export default () => {
  const correctPassword = "Sohm";
  const startDate = moment([2019, 4, 26, 12, 0, 0]);
  const currentDate = moment();
  const showDownload = currentDate.diff(startDate) >= 0;
  const [password, setPassword] = useState("");
  const scrollTo = element => {
    scroller.scrollTo(element, {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: "scroll"
    });
  };
  return (
    <Page
      title={"Großfamilie Sohm"}
      description={"Treffen am 26. Mai 2019 im Gasthaus Ochsen"}
    >
      <ScrollWrapper id="scroll">
        <ScrollItem full="true">
          <Background>
            <ScrollHeader>
              <Title>Großfamilie Sohm</Title>
              <SubTitle>Treffen am 25. Mai 2019 im Gasthaus Ochsen</SubTitle>
            </ScrollHeader>
            <ScrollIconWrapper>
              <ScrollIcon onClick={() => scrollTo("content")}>
                <FiChevronsDown />
              </ScrollIcon>
            </ScrollIconWrapper>
          </Background>
        </ScrollItem>
        <ScrollItem name="content">
          <ScrollItemContent>
            <ScrollItemContentTitle>
              Downloads gibt es hier:
            </ScrollItemContentTitle>
            {password.toLowerCase() !== correctPassword.toLowerCase() &&
              !showDownload && (
                <ScrollItemContentText>
                  Am 26. Mai um 12:00 erscheint hier ein Eingabefeld für das
                  Passwort.
                </ScrollItemContentText>
              )}
            {password.toLowerCase() !== correctPassword.toLowerCase() &&
              showDownload && (
                <ScrollItemContentText>
                  Bitte gib das Passwort ein, um zu den Downloads zu gelangen.
                  <ScrollItemContentInput
                    placeholder="Passwort eingeben"
                    value={password}
                    onChange={({ target: { value } }) => setPassword(value)}
                  />
                </ScrollItemContentText>
              )}
            {password.toLowerCase() === correctPassword.toLowerCase() &&
              showDownload && (
                <ScrollItemContentText>
                  Hier gibt es eine Anzahl an verschiedenen Downloads.
                  <DownloadItemList>
                    <DownloadItem
                      url={
                        "https://docs.google.com/uc?export=download&id=1JNEM_A3HSWFDo1nOE_9FFBT4vYXoYLm2"
                      }
                      target="_blank"
                      icon={<FiSave />}
                      text={"Stammbaum Großfamilie Sohm"}
                    />
                    <DownloadItem
                      url={
                        "https://docs.google.com/uc?export=download&id=1UZhPDrMDD1-PiiJ0Ptgpm95IbERG_TVd"
                      }
                      target="_blank"
                      icon={<FiSave />}
                      text={"Präsentation vom Treffen"}
                    />
                    <DownloadItem
                      url={
                        "https://docs.google.com/uc?export=download&id=1LFPL3Ot-Jj0Y7bYEKODFvPRQluq4Sdig"
                      }
                      target="_blank"
                      icon={<FiSave />}
                      text={"Fotos vom Abend"}
                    />
                    <DownloadItem
                      url={
                        "https://docs.google.com/uc?export=download&id=15AUPh5OBijrgMCo7d-WjJ-9A0BgoDtMp"
                      }
                      target="_blank"
                      icon={<FiSave />}
                      text={"alte Fotos der Familie Sohm"}
                    />
                  </DownloadItemList>
                </ScrollItemContentText>
              )}
          </ScrollItemContent>
        </ScrollItem>
      </ScrollWrapper>
    </Page>
  );
};
