import React, { Component } from "react";
import { createGlobalStyle, ThemeProvider } from "styled-components";
import theme from "../constants/theme";
import { AppWrapper } from "../components/wrapper";
import Navigation from "../screens/navigation";
import bold from "../static/fonts/bold.ttf";
import regular from "../static/fonts/regular.ttf";
import handwriting from "../static/fonts/handwriting.ttf";
import boldWoff from "../static/fonts/bold.woff";
import regularWoff from "../static/fonts/regular.woff";
import handwritingWoff from "../static/fonts/handwriting.woff";

const GlobalStyle = createGlobalStyle`

	@font-face {
			font-family: 'Amaranth';
			src: url(${boldWoff}) format('woff'), url(${bold}) format('truetype');
			font-weight: bold;
			font-style: normal;
	}

	@font-face {
			font-family: 'Amaranth';
			src: url(${regularWoff}) format('woff'), url(${regular}) format('truetype');
			font-weight: normal;
			font-style: normal;
	}

		@font-face {
			font-family: 'Damion';
			src: url(${handwritingWoff}) format('woff'), url(${handwriting}) format('truetype');
			font-weight: normal;
			font-style: normal;
	}

	body, html {
		margin: 0;
		padding: 0;
		font-family: 'Amaranth';
		font-size: ${theme.fonts.regular};
		color: ${theme.colors.dark};
		font-weight: 500;
	}

	* {
		font-family: 'Amaranth';
		font-weight: 500;
		font-size: ${theme.fonts.regular};
	}

	html, body, #root {
		height: 100%;
		width: 100%;
		overflow-x: hidden;
		background: ${theme.colors.white};
	}
`;

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <AppWrapper>
          <Navigation />
          <GlobalStyle />
        </AppWrapper>
      </ThemeProvider>
    );
  }
}
