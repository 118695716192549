export default {
  border: {
    radius: "2px"
  },
  fonts: {
    small: "15px",
    regular: "20px",
    medium: "25px",
    large: "30px"
  },
  colors: {
    white: "white",
    dark: "#364658",
    darkgray: "#7B8FA5",
    gray: "#A5BAD0",
    lightgray: "#DEE5ED",
    lila: "#7B8FA5",
    blue: "#37A0F6",
    red: "red",
    lightred: "#ff9494",
    opacegray: "#00000080"
  },
  breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 }
};
