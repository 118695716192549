import React, { createContext } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import Home from "../screens/home";
import { PublicRoute } from "../components/router";

export const LanguagesContext = createContext(null);

export default () => {
  return (
    <Router>
      <Switch>
        <PublicRoute exact path={`/`} component={Home} />
        <Redirect from={"*"} to={"/"} />
      </Switch>
    </Router>
  );
};
