import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { DoubleBounce } from "styled-spinkit";
import theme from "../../constants/theme";

export const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
`;

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const StyledDoubleBounce = () => (
  <DoubleBounce color={theme.colors.blue} size={60} />
);

export const Loading = () => (
  <LoadingWrapper>
    <StyledDoubleBounce />
  </LoadingWrapper>
);

export const Page = ({ title, description, children }) => {
  return (
    <PageWrapper>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
      </Helmet>
      {children}
    </PageWrapper>
  );
};
